.retreat-section {
  max-width: 1240px;
  width: 100%;
  margin-inline: auto;
  // margin-block: 50px;
  .img-section {
    background-color: #fdefe6;
    img {
      width: 100%;
    }
    .info {
      padding-inline: 44px;
      padding-block: 30px;
      display: flex;
      align-items: center;
      gap: 50px;
      @media (max-width: 992px) {
        flex-direction: column;
        gap: 20px;
      }
      @media (max-width: 768px) {
        padding-inline: 20px;
      }
      h2 {
        font-family: "Lora", serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        color: #7c5531;
        text-transform: uppercase;
      }
      // h4 {
      //   color: #343434;
      //   font-family: "Montserrat", sans-serif;
      //   font-size: 19px;
      //   font-weight: 700;
      //   line-height: 27px;
      // }
      p {
        color: #343434;
        font-family: "Montserrat", sans-serif;
        font-size: 19px;
        font-weight: 400;
        line-height: 27px;
        text-align: left;
        max-width: 599px;
        @media (max-width: 992px) {
          line-height: 25px;
          max-width: 100%;
          font-size: 16px;
        }
      }
    }
  }
  .retreat-text-section {
    max-width: 1050px;
    padding-inline: 20px;
    width: 100%;
    margin-inline: auto;
    margin-top: 100px;
    h3 {
      color: #7c5531;
      font-family: "Montserrat", sans-serif;
      font-size: 19px;
      font-weight: 700;
      line-height: 27px;
      margin-bottom: 20px;
    }
    p {
      color: #343434;
      font-family: "Montserrat", sans-serif;
      font-size: 19px;
      font-weight: 400;
      line-height: 27px;
      margin-bottom: 20px;
      @media (max-width: 992px) {
        line-height: 25px;
        font-size: 16px;
      }
    }
  }
}
.retreat-faq {
  max-width: 1050px !important;
  margin-block: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 100px !important;
  p {
    padding-bottom: 20px;
  }
  .accordion__button {
    &::before {
      transform: rotate(45deg);
    }
  }
  .accordion__button[aria-expanded="true"]::before,
  .accordion__button[aria-selected="true"]::before {
    transform: rotate(-135deg);
  }
}
