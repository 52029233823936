.event-property {
  padding-inline: 20px;
  h2 {
    color: #ba936f;
    font-family: "Lora", serif;
    font-size: 45px;
    font-weight: 400;
    line-height: 57.6px;
    margin-bottom: 40px;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: 768px) {
      font-size: 35px;
      margin-bottom: 20px;
      line-height: 47.6px;
    }
  }
  p {
    max-width: 1040px;
    margin-inline: auto;
    width: 100%;
    color: #343434;
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 20px;
      line-height: 20px;
    }
  }
  .event-card {
    max-width: 1126px;
    width: 100%;
    margin-inline: auto;
    display: flex;
    align-items: center;
    gap: 38px;
    background: #fdefe6;
    border-radius: 12px;
    padding: 28px;
    margin-block: 40px;
    @media (max-width: 992px) {
      padding: 18px;
      flex-direction: column;
      gap: 20px;
      align-items: normal;
    }
    .event-card-img {
      max-width: 620px;
      @media (max-width: 992px) {
        max-width: none;
      }
      img {
        width: 100%;
      }
    }
    .event-card-info {
      max-width: 409px;
      width: 100%;
      margin-inline: auto;
      @media (max-width: 992px) {
        max-width: none;
      }
      h3 {
        color: #343434;
        font-family: "Montserrat", sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 29.26px;
        margin-bottom: 15px;
      }
      p {
        font-family: "Montserrat", sans-serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 20.72px;
        color: #343434;
        text-align: left;
        margin-bottom: 30px;
      }
      .card-bottom {
        button {
          background-color: #ba936f;
          border: 0;
          min-width: 173px;
          text-align: center;
          border-radius: 8px;
          font-family: "Montserrat", sans-serif;
          font-size: 17px;
          font-weight: 500;
          line-height: 20.72px;
          color: #ffffff;
          padding-block: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .event-text {
    max-width: 1042px;
    width: 100%;
    margin-inline: auto;
    padding-inline: 20px;
    margin-bottom: 120px;
    h4 {
      color: #000000;
      font-family: "Montserrat", sans-serif;
      font-size: 19px;
      font-weight: 700;
      line-height: 27px;
      text-align: center;
      margin-bottom: 24px;
    }
    p {
      color: #343434;
      font-family: "Montserrat", sans-serif;
      font-size: 19px;
      font-weight: 400;
      line-height: 27px;
      text-align: center;
      margin-bottom: 20px;
      @media (max-width: 992px) {
        line-height: 25px;
        font-size: 16px;
      }
    }
  }
}
