.instagram-posts {
  max-width: 1300px;
  padding: 100px 20px;
  width: 100%;
  margin-inline: auto;
  h3 {
    color: #ba936f;
    font-family: "Lora", serif;
    font-size: 35px;
    font-weight: 400;
    line-height: 44.8px;
    text-transform: uppercase;
    margin-bottom: 34px;
  }
  p {
    color: #343434;
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 20.72px;
    margin-bottom: 30px;
    max-width: 711px;
    width: 100%;
  }
  .tags {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 20.72px;
    text-decoration: none;
    color: #ba936f;
  }
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 3px;
    @media (max-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }
    a {
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
      img {
        max-height: 400px;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
      p {
        font-family: "Montserrat", sans-serif;
        color: #343434;
        padding-right: 25px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        text-overflow: ellipsis;
        /* Optional: add some styling */
        line-height: 1.5; /* Adjust based on your font-size */
        max-height: 400px;
        margin-bottom: 20px;
      }
    }
  }
}
