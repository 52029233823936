@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
.home-hero {
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 768px) {
    align-items: flex-end;
  }
  .hero-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: 1;
    img {
      width: 100%;
      max-height: 100vh;
      min-height: 100vh;
      object-fit: cover;
    }
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 1;
    }
  }
  .event-hero-content {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    width: 100%;
    margin-inline: auto;
    padding-inline: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
      padding-bottom: 80px;
    }
    h1 {
      max-width: none;
      width: 100%;
      font-family: "Lora", serif;
      font-size: 48px;
      font-weight: 400;
      line-height: 64px;
      color: #ffffff;
      text-transform: capitalize;
      @media (max-width: 768px) {
        max-width: 75%;
        font-size: 35px;
        line-height: 44px;
      }
    }
    h3 {
      max-width: 442px;
      width: 100%;
      font-family: "Lora", serif;
      font-size: 24px;
      font-weight: 400;
      line-height: 30.72px;
      color: #ffffff;
      text-transform: capitalize;
      margin-top: 5px;
      margin-bottom: 10px;
    }
    p {
      max-width: 442px;
      width: 100%;
      font-family: "Lora", serif;
      font-size: 17px;
      font-weight: 400;
      line-height: 27px;
      color: #ffffff;
      text-transform: capitalize;
    }
  }
  .hero-logo {
    position: absolute;
    bottom: -100px;
    left: 0px;
    max-width: 340px;
    width: 100%;
    z-index: 1;
    img {
      width: 100%;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
}
