.text-section {
  background-color: #fdefe6;
  .container {
    padding: 25px 20px;
    max-width: 1300px;
    margin-inline: auto;
    display: flex;
    gap: 60px;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 16px;
    }
    .text {
      max-width: 576px;
      width: 100%;
      p {
        font-family: "Montserrat", sans-serif;
        font-size: 19px;
        font-weight: 400;
        line-height: 27px;
      }
    }
  }
}
