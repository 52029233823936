.card-section {
  max-width: 1052px;
  width: 100%;
  margin-inline: auto;
  padding-inline: 20px;
  .event-anchor-links {
    margin-block: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 60px;
    @media (max-width: 768px) {
      gap: 30px;
    }
    a {
      cursor: pointer;
      font-family: "Lora", serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 27px;
      margin-bottom: 20px;
      color: #343434;
      text-decoration: none;
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
  .gallery-text {
    margin-bottom: 90px;
    margin-top: 80px;
    position: relative;
    text-align: center;
    max-width: 1012px;
    margin-inline: auto;
    &.marginTop {
      margin-top: 150px;
    }
    p {
      position: relative;
      font-family: "Montserrat", sans-serif;
      font-size: 19px;
      font-weight: 400;
      line-height: 27px;
      margin-bottom: 20px;
      color: #343434;
      @media (max-width: 768px) {
        line-height: 25px;
        font-size: 16px;
      }
    }
    .bg-logo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 245px;
      z-index: 0;
      img {
        width: 100%;
      }
    }
  }
  .dark-color {
    color: #7c5531;
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    font-weight: 700;
    line-height: 27px;
    max-width: 686px;
    width: 100%;
    text-align: center;
    margin-inline: auto;
    padding-inline: 20px;
  }
  .cards {
    display: flex;
    gap: 24px;
    margin-bottom: 50px;
    align-items: baseline;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 24px;
    }
  }
}
