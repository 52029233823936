@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
.home-hero {
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 768px) {
    align-items: flex-end;
  }
  .hero-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: 1;
    img {
      width: 100%;
      max-height: 100vh;
      min-height: 100vh;
      object-fit: cover;
      &.web {
        display: inherit;
        object-fit: cover;
        @media (max-width: 768px) {
          display: none;
        }
      }
      &.mbl {
        display: none;
        object-fit: cover;
        @media (max-width: 768px) {
          display: inherit;
        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: linear-gradient(
        258.12deg,
        rgba(0, 0, 0, 0) 62.29%,
        rgba(0, 0, 0, 0.59) 71.91%,
        #000000 100%
      );
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  .hero-content {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    width: 100%;
    margin-inline: auto;
    padding-inline: 20px;
    @media (max-width: 768px) {
      padding-bottom: 80px;
    }
    h1 {
      width: 100%;
      font-family: "Lora", serif;
      font-size: 50px;
      font-weight: 400;
      line-height: 64px;
      color: #ffffff;
      text-transform: capitalize;
      @media (max-width: 768px) {
        max-width: 75%;
        font-size: 35px;
        line-height: 44px;
      }
      &.main-hero-title {
        max-width: 442px;
      }
    }
    .anchor-link {
      margin-top: 70px;
      position: relative;
      display: flex;
      align-items: center;
      gap: 24px;
      padding-left: 50px;
      width: fit-content;
      // cursor: pointer;
      @media (max-width: 768px) {
        display: none;
      }
      .hero-circle {
        position: absolute;
        left: 0;
        @media (max-width: 768px) {
          display: none;
        }
      }
      span {
        color: #ffffff;
        font-family: "Lora", serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 27px;
      }
    }
  }
  .hero-logo {
    position: absolute;
    bottom: -100px;
    left: 0px;
    max-width: 340px;
    width: 100%;
    z-index: 1;
    img {
      width: 100%;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.step-one-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  .col {
    flex-basis: calc(50% - 20px);
    height: 100%;
    .stepper-img {
      width: 100%;
      height: 100%;
    }
    h2 {
      color: #888888;
      font-weight: 400;
      font-family: "Lora", serif;
      font-size: 35px;
      max-width: 400px;
      margin-bottom: 16px;
      text-transform: uppercase;
    }
    h3 {
      text-transform: uppercase;
      color: #ba936f;
      font-size: 24px;
      font-weight: 700;
      font-family: "Lora", serif;
      margin-bottom: 16px;
      max-width: 366px;
    }
    p {
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      margin-bottom: 10px;
    }
    span {
      display: block;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 16px;
      font-weight: 600;
      color: #ba936f;
      margin-bottom: 5px;
      &.marginTop {
        margin-top: 50px;
      }
    }
    button {
      all: unset;
      width: 200px;
      height: 46px;
      background-color: #ba936f;
      border-radius: 15px;
      font-weight: 500;
      font-size: 16px;
      color: #fff;
      font-family: "Montserrat", sans-serif;
      text-align: center;
      margin-top: 10px;
      cursor: pointer;
    }
  }
}
.payment-step {
  h2 {
    font-family: "Lora", serif;
    font-size: 35px;
    font-weight: 400;
    line-height: 44.8px;
    text-align: left;
    margin-bottom: 20px;
    color: #ba936f;
  }
  p {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 21.42px;
    text-align: left;
    color: #343434;
    margin-bottom: 60px;
  }
  .payment-card-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
}
.payment-card {
  flex-basis: calc(25% - 2.5px);
  background: rgba(253, 239, 230, 0.99);
  border-radius: 16px;
  padding-inline: 15px;
  padding-block: 30px;
  padding-block-start: 40px;
  text-align: center;
  min-height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  &.confirm-payment {
    min-height: auto;
    gap: 10px;
    h5 {
      font-family: "Montserrat", sans-serif;
      min-height: auto;
      font-size: 17px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      color: #888888;
      max-width: 400px;
      width: 100%;
    }
    p {
      min-height: auto;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 15px;
      font-weight: 400;
      text-align: center;
      line-height: 22px;
      color: #343434;
      max-width: 500px;
      width: 100%;
    }
    span {
      text-align: left;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 16px;
      font-weight: 600;
      color: #ba936f;
      margin-bottom: 16px;
    }
  }
  h3 {
    font-family: "Lora", serif;
    font-size: 25px;
    font-weight: 600;
    color: #ba936f;
    margin-bottom: 16px;
  }
  h4 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 16px;
    color: #ba936f;
  }
  h5 {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    color: #888888;
    margin-bottom: 16px;
    min-height: 65px;
  }
  button {
    all: unset;
    width: 200px;
    height: 46px;
    background-color: #ba936f;
    border-radius: 15px;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
  }
}
.form-step {
  h1 {
    font-family: "Lora", serif;
    font-size: 35px;
    font-weight: 400;
    text-align: left;
    color: #ba936f;
    margin-bottom: 40px;
  }
  .form-step-wrapper {
    display: flex;
    gap: 40px;
    @media (max-width: 992px) {
      flex-direction: column;
      gap: 20px;
    }

    .col {
      &.left-col {
        min-width: 275px;
        border-right: 1px solid #888888;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 992px) {
          border-right: 0;
        }
        ul {
          @media (max-width: 992px) {
            display: flex;
            gap: 25px;
            width: 100%;
            overflow: auto;
            justify-content: space-between;
          }
          list-style: none;
          li {
            @media (max-width: 992px) {
              display: flex;
              flex-direction: column;
              gap: 10px;
              justify-content: center;
              align-items: center;
              text-align: center;
              white-space: nowrap;
            }
            margin-bottom: 40px;
            &.active {
              span {
                background-color: #ba936f;
              }
              font-weight: 700;
              color: #ba936f;
            }
            span {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              width: 50px;
              height: 50px;
              border-radius: 50%;
              font-family: "Plus Jakarta Sans", sans-serif;
              font-size: 16px;
              font-weight: 800;
              color: #fff;
              background-color: #d9d9d9;
              margin-right: 20px;
            }
            font-family: "Plus Jakarta Sans", sans-serif;
            font-size: 16px;
            font-weight: 400;
            color: #888888;
          }
        }
      }
      // &.right-col {
      //   min-width: 475px;
      // }
    }
    .form-group {
      label {
        font-family: "Plus Jakarta Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        margin-bottom: 10px;
        display: inline-block;
        &.marginTop {
          margin-top: 20px;
        }
      }
      input,
      select {
        width: 100%;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 24px;
        height: 48px;
        padding-inline: 15px;
        border-radius: 8px;
        color: #000;
        outline: none;
        margin-bottom: 10px;
        &:focus,
        &:active {
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        }
        &::-ms-input-placeholder {
          font-style: italic;
          color: rgba(206, 206, 206, 1);
        }

        &::placeholder {
          font-style: italic;
          color: rgba(206, 206, 206, 1);
        }
      }
      // select {
      //   width: auto;
      // }
      button {
        all: unset;
        width: 100%;
        // min-width: 300px;
        min-height: 45px;
        background-color: #ba936f;
        border-radius: 10px;
        font-weight: 500;
        font-size: 16px;
        color: #fff;
        font-family: "Montserrat", sans-serif;
        text-align: center;
        margin-top: 10px;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        margin-top: 25px;
        margin-inline: auto;
      }
      .form-group-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        flex-wrap: wrap;
        &.wrapper-col-3 {
          input {
            flex-basis: calc(33.33% - 10px);
            margin: 0;
          }
        }
        input {
          flex-basis: calc(50% - 5px);
          margin: 0;
        }
      }
    }
  }
}
