@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
.welcome-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  max-width: 1250px;
  width: 100%;
  border: 0;
  background-color: #fdefe6;
  padding: 25px;
  overflow: auto;
  height: auto;
  max-height: calc(100vh - 40px);
  // max-height: 655px;
  @media (max-width: 1270px) {
    max-width: 95%;
  }
  .close-img {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  .main-div {
    display: flex;
    align-items: center;
    gap: 50px;
    @media (max-width: 768px) {
      gap: 20px;
    }
    @media (max-width: 992px) {
      flex-direction: column-reverse;
      padding-top: 20px;
    }
    .left {
      max-width: 600px;
      width: 100%;
      @media (max-width: 768px) {
        max-width: 300px;
      }
      .upper {
        position: relative;
        &:last-child {
          margin-top: 20px;
        }
        .img {
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 20px 10px;
          margin-top: -4px;
          @media (max-width: 768px) {
            // flex-direction: column;
            justify-content: space-between;
            // position: static;
            padding: 10px 10px;
          }
          h2 {
            color: #7c5531;
            font-family: "Lora", serif;
            font-size: 24px;
            font-weight: 400;
            line-height: 30.72px;
            text-transform: uppercase;
            @media (max-width: 768px) {
              font-size: 16px;
              line-height: 24px;
            }
          }
          h4 {
            color: #343434;
            font-family: "Montserrat", sans-serif;
            font-size: 13px;
            font-weight: 700;
            line-height: 27px;
            @media (max-width: 768px) {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
    }
    .right {
      text-align: center;
      max-width: 500px;
      width: 100%;
      h3 {
        color: #7c5531;
        font-family: "Lora", serif;
        font-size: 24px;
        line-height: 30.72px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 10px;
        @media (max-width: 1270px) {
          font-size: 20px;
          line-height: 26.72px;
        }
        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      h2 {
        color: #7c5531;
        font-family: "Lora", serif;
        font-size: 48px;
        font-weight: 400;
        line-height: 61.44px;
        margin-bottom: 10px;
        @media (max-width: 1270px) {
          font-size: 35px;
          line-height: 47.72px;
        }
        @media (max-width: 992px) {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 0px;
        }
      }
      h5 {
        color: #7c5531;
        font-family: "Lora", serif;
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 10px;
        line-height: 61.44px;
        @media (max-width: 1270px) {
          font-size: 22px;
          line-height: 57.72px;
        }
        @media (max-width: 992px) {
          margin-bottom: 0px;
        }
      }
      h4 {
        font-family: "Lora", serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 30.72px;
        text-align: center;
        color: #ba936f;
        margin-bottom: 10px;
        text-transform: uppercase;
        max-width: 360px;
        width: 100%;
        margin-inline: auto;
        @media (max-width: 1270px) {
          font-size: 20px;
          line-height: 26.72px;
        }
        @media (max-width: 992px) {
          display: none;
        }
      }
      p {
        font-family: "Plus Jakarta Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        text-align: center;
        color: #343434;
        margin-bottom: 15px;
        max-width: 404px;
        width: 100%;
        margin-inline: auto;
        @media (max-width: 1270px) {
          max-width: 404px;
          font-size: 14px;
          line-height: 24.72px;
        }
        @media (max-width: 992px) {
          // max-width: 100%;
          display: none;
        }
      }
      a {
        min-width: 230px;
        margin-inline: auto;
        background: #ba936f;
        color: #fff;
        min-height: 46px;
        border-radius: 8px;
        font-family: "Montserrat", sans-serif;
        font-size: 17px;
        font-weight: 500;
        line-height: 20.72px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        max-width: fit-content;
        @media (max-width: 768px) {
          font-size: 15px;
          min-width: 130px;
          min-height: 36px;
        }
      }
    }
  }
}
