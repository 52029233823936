.home-gallery {
  padding-block: 108px;
  max-width: 916px;
  width: 100%;
  margin-inline: auto;
  .gallery-text {
    margin-bottom: 180px;
    margin-top: 50px;
    position: relative;
    text-align: center;
    @media (max-width: 768px) {
      padding-inline: 20px;
    }
    p {
      position: relative;
      font-family: "Montserrat", sans-serif;
      font-size: 19px;
      font-weight: 400;
      line-height: 27px;
    }
    .bg-logo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 245px;
      z-index: 0;
      img {
        width: 100%;
      }
    }
  }
  .gallery-img {
    img {
      width: 100%;
    }
  }
}
