.facilitators-section {
  padding-block: 100px;
  max-width: 1168px;
  width: 100%;
  margin-inline: auto;
  padding-inline: 20px;
  @media (max-width: 768px) {
    padding-top: 0;
  }
  h2 {
    color: #ba936f;
    font-family: "Lora", serif;
    font-size: 45px;
    font-weight: 400;
    line-height: 57.6px;
    text-transform: uppercase;
    text-align: center;
    padding-inline: 20px;
    width: 100%;
    margin-bottom: 100px;
    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 45.6px;
    }
  }
  .wrapper {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    @media (max-width: 992px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .facilitator-cards {
      display: flex;
      flex-direction: column;
      gap: 24px;
      h3 {
        color: #ba936f;
        font-family: "Lora", serif;
        font-size: 45px;
        font-weight: 400;
        line-height: 57.6px;
        @media (max-width: 768px) {
          font-size: 36px;
          line-height: 45px;
        }
      }
      .card {
        border-radius: 12px;
        background: #fdefe6;
        padding: 24px;
        display: flex;
        gap: 24px;
        justify-content: space-between;
        @media (max-width: 768px) {
          flex-direction: column;
        }
        .img {
          width: 200px;
          height: 300px;
          // @media (max-width: 768px) {
          //   height: 200px;
          // }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .info {
          color: #7c5531;
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
          line-height: 17.07px;
          max-width: 285px;
          width: 100%;
          @media (max-width: 768px) {
            max-width: 100%;
          }

          h4 {
            margin-bottom: 12px;
            font-weight: 700;
          }
          p {
            font-weight: 400;
            color: #000;
            &:not(:last-child) {
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }
}
