header {
  background: linear-gradient(
    88.78deg,
    rgba(186, 147, 111, 0.7) -13.11%,
    rgba(124, 85, 49, 0.7) 16.94%
  );
  min-height: 78px;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 99;
  @media (max-width: 768px) {
    background: #ba936f;
  }
  nav {
    max-width: 1200px;
    width: 100%;
    margin-inline: auto;
    padding-inline: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .logo {
      img {
        @media (max-width: 768px) {
          width: 200px;
        }
      }
    }
    .menu-toggle {
      color: #ffffff;
      font-size: 30px;
      font-weight: 700;
      @media (min-width: 768px) {
        display: none;
      }
    }
    .nav-links {
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 300px;
      width: 100%;
      gap: 20px;
      @media (max-width: 768px) {
        display: none;
      }
      &.active {
        position: absolute;
        width: 100%;
        min-height: 150px;
        background-color: #ba936f;
        top: 63px;
        display: block;
        padding: 20px;
        right: 0px;
        max-width: none;
        text-align: center;

        li {
          padding-bottom: 20px;
        }
      }
      li {
        a {
          font-family: "Lora", serif;
          font-size: 16px;
          color: white;
          font-weight: 400;
          text-decoration: none;
        }
        span {
          color: #ba936f;
          margin-top: -5px;
          padding: 3px 7px;
          font-weight: 500;
          border-radius: 6px;
          font-size: 14px;
          animation: fade 1s infinite alternate;
        }
      }
    }
  }
  /* src/components/Navbar.css */
  // .navbar {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   padding: 1rem 2rem;
  //   background-color: #333;
  //   color: #fff;
  // }

  // .logo {
  //   font-size: 1.5rem;
  //   font-weight: bold;
  // }

  // .nav-links {
  //   list-style: none;
  //   display: flex;
  //   gap: 1rem;
  // }

  // .nav-links li a {
  //   color: #fff;
  //   text-decoration: none;
  //   transition: color 0.3s;
  // }

  // .nav-links li a:hover {
  //   color: #f39c12;
  // }

  // @media (max-width: 768px) {
  //   .nav-links {
  //     flex-direction: column;
  //     background-color: #444;
  //     position: absolute;
  //     top: 60px;
  //     right: 0;
  //     width: 200px;
  //     display: none;
  //   }

  //   .nav-links.active {
  //     display: flex;
  //   }

  //   .navbar {
  //     flex-direction: column;
  //     align-items: flex-start;
  //   }

  //   .menu-toggle {
  //     display: block;
  //     cursor: pointer;
  //   }
  // }
}

@keyframes fade {
  from {
    background-color: rgba(255, 255, 255, 0.4);
    color: #ffffff;
  }
  to {
    background-color: rgba(255, 255, 255, 1);
    color: #ba936f;
  }
}
