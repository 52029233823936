.home-about {
  max-width: 1300px;
  width: 100%;
  margin-inline: auto;
  padding-block: 164px 108px;
  padding-inline: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  @media (max-width: 992px) {
    flex-direction: column;
  }
  .text-content {
    max-width: 560px;
    width: 100%;
    // min-width: 400px;
    @media (max-width: 992px) {
      min-width: auto;
      max-width: 100%;
    }
    h3 {
      color: #ba936f;
      font-family: "Lora", serif;
      font-size: 35px;
      font-weight: 400;
      line-height: 44.8px;
      text-transform: uppercase;
    }
    p {
      padding-top: 30px;
      color: #343434;
      font-family: "Montserrat", sans-serif;
      font-size: 19px;
      font-weight: 400;
      line-height: 27px;
      @media (max-width: 992px) {
        padding-top: 15px;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  .img-content {
    max-width: 600px;
    margin-top: 40px;
    width: 100%;
    border-radius: 14px;
    @media (max-width: 992px) {
      max-width: none;
    }
    .swiper-slide {
      width: 100% !important;
      border-radius: 14px;
    }
    img {
      border-radius: 14px;
      width: 100%;
    }
  }
}
