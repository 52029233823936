* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-content {
  min-height: calc(100vh - 326px);
  /* padding-top: 78px; */
}

.thank-you {
  padding-top:100px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 440px);
  flex-direction: column;
  gap: 20px;
  padding-block: 50px;
  padding-inline: 10px;
  h2 {
    font-size: 45px;
    line-height: 55px;
    color: #ba936f;
    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 44px;
    }
    }
    p {
      font-size: 25px;
      line-height: 55px;
      color: #343434;
        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 24px;
        }
  }
}

.line-through {
  text-decoration: line-through;
}